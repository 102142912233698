import { useEffect, useState } from "preact/hooks";
import Spinner from "./Spinner.tsx";
import ErrorBoundary from "./ErrorBoundary.tsx";
import { JSX, Suspense } from "preact/compat";

interface LazyRouteProps {
  component: () => JSX.Element;
}

export default function LazyRoute({ component: Component }: LazyRouteProps) {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 200);

    return () => {
      clearTimeout(timer);
      setShowSpinner(false);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Suspense fallback={showSpinner ? <Spinner /> : null}>
        <Component />
      </Suspense>
    </ErrorBoundary>
  );
}
