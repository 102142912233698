import { useEffect, useState } from "preact/hooks";
import { toastTimeout } from "../constants.ts";
import clsx from "clsx/lite";
import { useAtom, PrimitiveAtom } from "jotai";

import "../styles/toast.scss";

export type ToastMessage = {
  index?: number;
  id: string;
  message: string;
  type: "success" | "failure";
  createdAt: number;
};

interface ToastProps {
  toastAtom: PrimitiveAtom<ToastMessage[]>;
}

export function Toast({ toastAtom }: ToastProps) {
  const [toasts, setToasts] = useAtom(toastAtom);

  const removeToast = (id: string) => {
    setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id));
  };

  if (!toasts.length) return null;

  return (
    <div class="toast-wrapper">
      {toasts.map((toast, index) => (
        <ToastItem key={toast.id} toast={{ ...toast, index }} onClose={removeToast} />
      ))}
    </div>
  );
}

function ToastItem({ toast, onClose }: { toast: ToastMessage; onClose: (id: string) => void }) {
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setIsFadingOut(true);
    }, toastTimeout - 500);

    const closeTimer = setTimeout(() => {
      onClose(toast.id);
    }, toastTimeout);

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(closeTimer);
    };
  }, []);

  return (
    <div
      class={clsx("toast", toast.type, isFadingOut && "fading-out")}
      style={{
        transform: `translateY(${-100 * toast.index}%)`,
        transition: "transform 0.3s ease-in-out"
      }}>
      <span>{toast.message}</span>
      <button onClick={() => onClose(toast.id)} class="close-button">
        &times;
      </button>
    </div>
  );
}
