import { Link, useLocation } from "wouter-preact";
import useDeviceType from "../hooks/useDeviceType.ts";
import { useEffect, useRef } from "preact/hooks";
import { useAtom, PrimitiveAtom } from "jotai";
import { clsx } from "clsx/lite";
import useDragDetection, { DragDirection } from "../hooks/useDragDetection.ts";
import { Route } from "../types.ts";

import "../styles/navigation.scss";

interface NavigationProps {
  routes: Route[];
  isNavigationOpenAtom: PrimitiveAtom<boolean>;
  isDialogOpenAtom: PrimitiveAtom<boolean>;
}

export default function Navigation({ routes, isNavigationOpenAtom, isDialogOpenAtom }: NavigationProps) {
  const [location] = useLocation();
  const [isOpen, setIsOpen] = useAtom(isNavigationOpenAtom);
  const [isDialogOpen] = useAtom(isDialogOpenAtom);
  const isMobile = useDeviceType();
  const dragElementRef = useRef<HTMLElement>();

  useEffect(() => {
    const element = dragElementRef.current;
    if (!element) return null;

    element.addEventListener("touchstart", handleTouchStart, { passive: true });
    element.addEventListener("touchmove", handleTouchMove, { passive: true });
    element.addEventListener("touchend", handleTouchEnd, { passive: true });

    return () => {
      element.removeEventListener("touchstart", handleTouchStart);
      element.removeEventListener("touchmove", handleTouchMove);
      element.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useDragDetection({
    handleValidDrag: (direction: DragDirection) => {
      if (isDialogOpen) return;

      if (direction === "left") {
        setIsOpen(false);
      }
    }
  });

  useEffect(() => {
    setIsOpen(!isMobile);
  }, [isMobile, setIsOpen]);

  useEffect(() => {
    if (!isMobile) return;

    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen, isMobile]);

  const handleNavigationClick = () => {
    if (isMobile) setIsOpen(false);
  };

  const handleOverlayClick = (evt: MouseEvent) => {
    if ((evt.target as HTMLElement).classList.contains("overlay")) setIsOpen(false);
  };

  return (
    <>
      <nav class={clsx("offcanvas", "navigation", "left", isOpen && "open")} ref={dragElementRef}>
        {isOpen && (
          <>
            {routes.map((route) => {
              if (route.path === "*" || route.hidden) return null;
              const IconComponent = route.icon;

              return (
                <div key={route.path} class={clsx("route", location === route.path && "active")}>
                  <Link href={route.path} class="link" onClick={handleNavigationClick}>
                    <span class="icon">{IconComponent && <IconComponent size={32} />}</span>
                    <span class="label">{route.labels.navigation}</span>
                  </Link>
                </div>
              );
            })}
          </>
        )}
      </nav>

      {isOpen && isMobile && <div class="overlay" onClick={handleOverlayClick} />}
    </>
  );
}
