import { render } from "preact";
import { Redirect, Route, Switch } from "wouter-preact";
import { routes } from "./routes.ts";
import Layout from "src/components/Layout.tsx";
import LazyRoute from "@fleetora/core/src/components/LazyRoute.tsx";
import { mobileBreakpoint } from "@fleetora/core/src/constants.ts";

import "@fleetora/core/src/styles/global.scss";

if (import.meta.env.MODE === "development" && window.matchMedia(`(width < ${mobileBreakpoint}px)`).matches) {
  const script = document.createElement("script");
  script.src = "https://cdn.jsdelivr.net/npm/eruda";
  script.onload = () => {
    // @ts-expect-error - eruda is a global variable
    window.eruda?.init();
  };

  document.body.appendChild(script);
}

export function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/">
          <Redirect to="/test-route" />
        </Route>

        {routes.map(({ path, component: Component }) => (
          <Route key={path} path={path}>
            <LazyRoute component={Component} />
          </Route>
        ))}
      </Switch>
    </Layout>
  );
}

render(<App />, document.getElementById("app"));
