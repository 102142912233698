import {
  dateOnlyRegex,
  iso8601Regex,
  numberToTractionMap,
  secondsInDay,
  secondsInHour,
  secondsInMinute,
  secondsInMonth,
  secondsInYear,
  timeProperties,
  timeSpanRegex,
  tractionToNumberMap,
  tractionToSeznamNumberMap,
  tramSetModelNames
} from "./constants.ts";
import { Carrier, ChronicleTag, DayOfWeek, JsonObject, Orderings, ProvozVehicle, Route, StopDeparture, Stops, TractionType } from "./types.ts";

const collator = new Intl.Collator("cs", {
  sensitivity: "base",
  usage: "sort",
  numeric: true
});

export function createToastMessage(title: string, message: string, type: "success" | "failure" = "failure") {
  return {
    id: Date.now().toString(),
    message: `${title}${message ? `: ${message}` : ""}`,
    type,
    createdAt: Date.now()
  };
}

export function findRoute(routes: Route[], location: string) {
  return (
    routes.find((route) => {
      if (location === "/" && route.default) return true;

      return route.path === location;
    }) || routes.find((x) => x.path === "*")
  );
}

export function isWorkingDay(dayOfWeek: DayOfWeek) {
  return dayOfWeek !== DayOfWeek.Saturday && dayOfWeek !== DayOfWeek.Sunday;
}

export function hasLineTramSets(dayOfWeek: DayOfWeek, line: string) {
  const isWorking = isWorkingDay(dayOfWeek);
  if (isWorking) return true;

  return line === "2" || line === "4";
}

export function hasModelTramSets(modelName: string) {
  return tramSetModelNames.includes(modelName);
}

//Returns:
// null -> vehicle model doesn't run in sets or it's the wrong day of week
// ? -> vehicle model runs in sets, but doesn't have a back vehicle number (ordering is solo or doesn't start with A+)
// number -> back vehicle number
export function getBackVehicleNumber(orderings: Orderings, frontNumber: number, line: string, modelName: string, dayOfWeek: DayOfWeek) {
  const ordering = orderings[frontNumber];
  const lineTramSets = hasLineTramSets(dayOfWeek, line);
  const modelTramSets = hasModelTramSets(modelName);

  if (!lineTramSets || !modelTramSets) return null;
  if (!ordering || ordering === "sólo" || !ordering.startsWith("A+")) return "?";

  return Number(ordering.slice(2));
}

export function getRegistrationNumber(vehicleId: string) {
  return Number(vehicleId.slice(1));
}

export function getTractionNumber(vehicleId: string) {
  return Number(vehicleId.charAt(0));
}

export function getTractionType(vehicleId: string) {
  const tractionNumber = getTractionNumber(vehicleId);

  return numberToTractionMap.get(tractionNumber) as TractionType;
}

export function uppercaseFirst(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function calculateVehicleProperties(obj: JsonObject) {
  obj["registrationNumber"] = getRegistrationNumber(obj.id as string);
  obj["tractionType"] = getTractionType(obj.id as string) as TractionType;

  return obj as unknown as ProvozVehicle;
}

export function calculateDepartureProperties(obj: JsonObject) {
  if (!obj.vehicleId) return obj;

  obj["registrationNumber"] = getRegistrationNumber(obj.vehicleId as string);
  obj["tractionType"] = getTractionType(obj.vehicleId as string) as TractionType;

  return obj as unknown as StopDeparture;
}

export function parseDateProperties(obj: JsonObject | JsonObject[]): unknown {
  if (typeof obj !== "object" || obj === null) return obj;

  if (Array.isArray(obj)) return obj.map((item) => parseDateProperties(item));

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      obj[key] = parseDateProperties(value as JsonObject);
    } else if (timeProperties.includes(key) && typeof value === "string") {
      const timeSpanMatch = value.match(timeSpanRegex);
      if (timeSpanMatch) {
        const [_, hours, minutes, seconds] = timeSpanMatch;
        let hoursNum = Number(hours);

        const date = new Date(1, 0, 1);

        const days = Math.floor(hoursNum / 24);
        hoursNum = hoursNum % 24;

        date.setDate(date.getDate() + days);
        date.setHours(hoursNum);
        date.setMinutes(Number(minutes));
        date.setSeconds(Number(seconds));

        obj[key] = date;
        return;
      }

      const dateTimeMatch = value.match(iso8601Regex);
      if (dateTimeMatch) {
        obj[key] = new Date(value);
        return;
      }

      const dateOnlyMatch = value.match(dateOnlyRegex);
      if (dateOnlyMatch) {
        obj[key] = new Date(`${value}T00:00:00Z`);
        return;
      }
    }
  });

  return obj;
}

export function getHhMm(date: Date) {
  return date.toLocaleTimeString("cs-CZ", { hour: "2-digit", minute: "2-digit" });
}

export function toIso8601DateOnly(date: Date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
}

export function addMinutes(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000);
}

export function secondsToHuman1(seconds: number) {
  if (seconds >= secondsInYear) {
    const years = Math.floor(seconds / secondsInYear);
    return [years, years === 1 ? "rok" : years >= 2 && years <= 4 ? "roky" : "let"];
  } else if (seconds >= secondsInMonth) {
    const months = Math.floor(seconds / secondsInMonth);
    return [months, months === 1 ? "měsíc" : months >= 2 && months <= 4 ? "měsíce" : "měsíců"];
  } else if (seconds >= secondsInDay) {
    const days = Math.floor(seconds / secondsInDay);
    return [days, days === 1 ? "den" : days >= 2 && days <= 4 ? "dny" : "dnů"];
  } else if (seconds >= secondsInHour) {
    const hours = Math.floor(seconds / secondsInHour);
    return [hours, "hod."];
  } else if (seconds >= secondsInMinute) {
    const minutes = Math.floor(seconds / secondsInMinute);
    return [minutes, "min."];
  }
  return [seconds, "s"];
}

export function secondsToHuman7(seconds: number) {
  if (seconds >= secondsInYear) {
    const years = Math.floor(seconds / secondsInYear);
    return [years, years === 1 ? "rokem" : years >= 2 && years <= 4 ? "roky" : "lety"];
  } else if (seconds >= secondsInMonth) {
    const months = Math.floor(seconds / secondsInMonth);
    return [months, months === 1 ? "měsícem" : "měsíci"];
  } else if (seconds >= secondsInDay) {
    const days = Math.floor(seconds / secondsInDay);
    return [days, days === 1 ? "dnem" : "dny"];
  } else if (seconds >= secondsInHour) {
    const hours = Math.floor(seconds / secondsInHour);
    return [hours, hours === 1 ? "hodinou" : "hodinami"];
  } else if (seconds >= secondsInMinute) {
    const minutes = Math.floor(seconds / secondsInMinute);
    return [minutes, minutes === 1 ? "minutou" : "minutami"];
  }
  return [seconds, seconds === 1 ? "sekundou" : "sekundami"];
}

export function getImageCacheUrl(documentationId: number, width: number, quality: number) {
  return `${import.meta.env.VITE_IMAGE_CACHE_ORIGIN}/?documentationId=${documentationId}&width=${width}&quality=${quality}`;
}

export function getSeznamUrl(tractionType: TractionType, registrationNumber: number) {
  const tractionNumber = tractionToSeznamNumberMap.get(tractionType);

  return `${import.meta.env.VITE_SEZNAM_ORIGIN}/seznam?evc=${registrationNumber}&tractionId=${tractionNumber}&iddopravce=${import.meta.env.VITE_CARRIER_ID}`;
}

export function getDocumentationUrl(documentationId: number) {
  return `${import.meta.env.VITE_SEZNAM_ORIGIN}/dokumentacka/${documentationId}`;
}

export function getCarrierUrl(connectionId: number) {
  return `${import.meta.env.VITE_CARRIER_ORIGIN}/provoz/?id=${connectionId}`;
}

export function getLineTractionType(lineName: string): TractionType {
  if (!lineName) return TractionType.Bus;

  const trimmedLine = lineName.trim();

  if (trimmedLine.startsWith("N")) {
    return TractionType.Bus;
  }

  if (trimmedLine.endsWith("A")) {
    return TractionType.Bus;
  }

  if (trimmedLine.endsWith("X")) {
    return getLineTractionType(trimmedLine.slice(0, -1));
  }

  if (trimmedLine.includes("/")) {
    const firstLine = trimmedLine.split("/")[0];
    return getLineTractionType(firstLine);
  }

  const lineNumber = Number(trimmedLine);
  if (!isNaN(lineNumber)) {
    return getTractionFromNumber(lineNumber);
  }

  return TractionType.Bus;
}

function getTractionFromNumber(num: number): TractionType {
  if (num < 9) return TractionType.Tram;
  if (num < 20) return TractionType.Trolleybus;
  return TractionType.Bus;
}

function getLineNumber(line: string): number {
  if (!line) return Infinity;
  line = line.trim();

  if (line.startsWith("N")) {
    return Infinity;
  }

  if (line.endsWith("A")) {
    return getLineNumber(line.slice(0, -1));
  }

  if (line.endsWith("X")) {
    return getLineNumber(line.slice(0, -1));
  }

  if (line.includes("/")) {
    return getLineNumber(line.split("/")[0]);
  }

  const num = Number(line);
  return isNaN(num) ? Infinity : num;
}

export function compareLines(a: string, b: string): number {
  const aIsNight = a.startsWith("N");
  const bIsNight = b.startsWith("N");

  if (aIsNight && !bIsNight) return 1;
  if (!aIsNight && bIsNight) return -1;

  const aNum = getLineNumber(a);
  const bNum = getLineNumber(b);

  if (aNum !== bNum) return aNum - bNum;

  const aTraction = getLineTractionType(a);
  const bTraction = getLineTractionType(b);

  return tractionToNumberMap.get(aTraction) - tractionToNumberMap.get(bTraction);
}

export function getModel(carrier: Carrier, tractionType: TractionType, registrationNumber: number) {
  const models = carrier[tractionType.toLowerCase()]; //TODO změnit v souboru
  if (!models) return null;

  return models.find((model) => model.registrationNumbers.includes(registrationNumber));
}

export function findStop(stops: Stops, query: string) {
  const exactMatch = Object.entries(stops).find(([name]) => compareInsensitive(name, query));

  if (exactMatch) return exactMatch;
  const startsWith = Object.entries(stops).find(([name]) => startsInsensitive(name, query));

  if (startsWith) return startsWith;
  const includes = Object.entries(stops).find(([name]) => includesInsensitive(name, query));

  if (includes) return includes;
  return null;
}

export function findStopNames(stops: Stops, query: string) {
  return Object.entries(stops)
    .filter(([name]) => includesInsensitive(name, query))
    .map(([name]) => name);
}

export function getMinutesToDate(date: Date) {
  const now = new Date();

  return Math.floor((date.getTime() - now.getTime()) / 60000);
}

export function compareInsensitive(a: string, b: string): boolean {
  return collator.compare(a, b) === 0;
}

export function includesInsensitive(text: string, searchStr: string) {
  const stringLength = text.length;
  const searchStringLength = searchStr.length;
  const lengthDiff = stringLength - searchStringLength;

  for (let i = 0; i <= lengthDiff; i++) {
    if (compareInsensitive(text.substring(i, i + searchStringLength), searchStr)) {
      return true;
    }
  }

  return false;
}

export function startsInsensitive(text: string, searchStr: string): boolean {
  const searchStringLength = searchStr.length;
  return compareInsensitive(text.substring(0, searchStringLength), searchStr);
}

export function chronicleTagToHuman(tag: ChronicleTag) {
  switch (tag) {
    case ChronicleTag.Line:
      return "Linka";
    case ChronicleTag.Timetable:
      return "Jízdní řády";
    case ChronicleTag.Fleet:
      return "Vozový park";
    case ChronicleTag.Change:
      return "Výluky a omezení";
    case ChronicleTag.HistoricalEvent:
      return "Historická událost";
    case ChronicleTag.Tariff:
      return "Tarif";
    case ChronicleTag.Infrastructure:
      return "Infrastruktura";
    case ChronicleTag.Other:
      return "Ostatní";
  }
}

export function limitWithEllipsis(text: string, maxLength: number, ellipsisLength: number) {
  if (text.length <= maxLength) return text;

  const ellipsis = ".".repeat(ellipsisLength);
  return `${text.slice(0, maxLength)}${ellipsis}`;
}

export function getSecondsDifference(date1: Date, date2: Date) {
  return (date1.getTime() - date2.getTime()) / 1000;
}
