import { useEffect, useState } from "preact/hooks";
import { mobileBreakpoint } from "../constants.ts";

function useDeviceType() {
  const [isMobile, setIsMobile] = useState(() => {
    return window.matchMedia(`(max-width: ${mobileBreakpoint}px)`).matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${mobileBreakpoint}px)`);

    const handleUpdate = (evt: MediaQueryListEvent) => {
      setIsMobile(evt.matches);
    };

    mediaQuery.addEventListener("change", handleUpdate);

    return () => {
      mediaQuery.removeEventListener("change", handleUpdate);
    };
  }, [mobileBreakpoint]);

  return isMobile;
}

export default useDeviceType;
