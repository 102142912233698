import { ComponentChildren } from "preact";
import { useEffect, useMemo, useRef } from "preact/hooks";
import { useAtom, useSetAtom } from "jotai";
import Navigation from "@fleetora/core/src/components/Navigation.tsx";
import Header from "src/components/Header.tsx";
import { useLocation } from "wouter-preact";
import useDeviceType from "@fleetora/core/src/hooks/useDeviceType.ts";
import { Toast } from "@fleetora/core/src/components/Toast.tsx";
import { findRoute } from "@fleetora/core/src/helpers.ts";
import useDragDetection, { DragDirection } from "@fleetora/core/src/hooks/useDragDetection.ts";
import { isDialogOpenAtom, isNavigationOpenAtom, toastAtom } from "src/store.ts";
import { routes } from "src/routes.ts";

interface LayoutProps {
  children: ComponentChildren;
}

export default function Layout({ children }: LayoutProps) {
  const isMobile = useDeviceType();
  const location = useLocation()[0];
  const [isDialogOpen] = useAtom(isDialogOpenAtom);
  const setIsNavigationOpen = useSetAtom(isNavigationOpenAtom);
  const dragElementRef = useRef<HTMLDivElement>(null);
  const isDialogOpenRef = useRef(false);

  useEffect(() => {
    const element = dragElementRef.current;
    if (!element) return null;

    element.addEventListener("touchstart", handleTouchStart, { passive: true });
    element.addEventListener("touchmove", handleTouchMove, { passive: true });
    element.addEventListener("touchend", handleTouchEnd, { passive: true });

    return () => {
      element.removeEventListener("touchstart", handleTouchStart);
      element.removeEventListener("touchmove", handleTouchMove);
      element.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  useEffect(() => {
    isDialogOpenRef.current = isDialogOpen;
  }, [isDialogOpen]);

  const route = useMemo(() => findRoute(routes, location), [location]);

  const handleValidDrag = (direction: DragDirection) => {
    if (isDialogOpenRef.current) return;

    if (direction === "right") {
      setIsNavigationOpen(true);
    }
  };

  const { handleTouchStart, handleTouchMove, handleTouchEnd } = useDragDetection({
    handleValidDrag
  });

  return (
    <>
      {!isDialogOpen && <Toast toastAtom={toastAtom} />}

      <Navigation routes={routes} isNavigationOpenAtom={isNavigationOpenAtom} isDialogOpenAtom={isDialogOpenAtom} />

      <div class="page" ref={dragElementRef}>
        <Header />

        <main class={route.className}>
          {route && !route.labels.useCustomHeader && route.labels.navigation && !isMobile && <h1>{route.labels.navigation}</h1>}

          {children}
        </main>
      </div>
    </>
  );
}
