import { lazy } from "preact/compat";
import { Activity } from "lucide-react";
import { Route } from "@fleetora/core/src/types.ts";

export const routes: Route[] = [
  {
    path: "/test-route",
    className: "TestRoute",
    labels: { navigation: "TestRoute" },
    icon: Activity,
    component: lazy(() => import("./pages/TestRoute/TestRoute.tsx")),
    default: true
  },

  {
    path: "*",
    className: "not-found",
    labels: { navigation: "404" },
    hidden: true,
    component: lazy(() => import("./pages/NotFound/NotFound.tsx"))
  }
];
