import clsx from "clsx/lite";
import { ComponentChildren } from "preact";

import "../styles/tag-button.scss";

interface IconProps {
  component: ComponentChildren;
  placement: "left" | "right";
}

interface TagButtonProps {
  variant: "primary" | "secondary" | "confirm" | "danger";
  type?: "button" | "submit" | "reset";
  icon?: IconProps;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  children: ComponentChildren;
}

export default function TagButton({ variant, type, icon, className, disabled, onClick, children }: TagButtonProps) {
  return (
    <button class={clsx("tag-button", variant, className)} onClick={onClick} type={type} disabled={disabled}>
      {icon && icon.placement === "left" && icon.component}
      {children}
      {icon && icon.placement === "right" && icon.component}
    </button>
  );
}
