import { useRef, useCallback } from "preact/hooks";
import { dragConfig } from "../constants.ts";

export type DragDirection = "left" | "right" | null;

interface DragCallbacks {
  handleValidDrag: (direction: DragDirection) => void;
}

function getScrollInfo(element: Element | null): {
  isScrollable: boolean;
  isAtLeftEdge: boolean;
  isAtRightEdge: boolean;
  scrollableElement: Element | null;
} {
  let currentElement = element;
  while (currentElement && currentElement !== document.body) {
    const style = window.getComputedStyle(currentElement);
    const overflow = style.getPropertyValue("overflow-x");

    if ((overflow === "auto" || overflow === "scroll") && currentElement.scrollWidth > currentElement.clientWidth) {
      const isAtLeftEdge = currentElement.scrollLeft <= 0;
      const isAtRightEdge = Math.abs(currentElement.scrollLeft + currentElement.clientWidth - currentElement.scrollWidth) <= 1;

      return {
        isScrollable: true,
        isAtLeftEdge,
        isAtRightEdge,
        scrollableElement: currentElement
      };
    }

    currentElement = currentElement.parentElement;
  }

  return {
    isScrollable: false,
    isAtLeftEdge: false,
    isAtRightEdge: false,
    scrollableElement: null
  };
}

export default function useDragDetection({ handleValidDrag }: DragCallbacks) {
  const touchStartX = useRef(0);
  const touchStartY = useRef(0);
  const isDragging = useRef(false);
  const dragDirection = useRef<DragDirection>(null);
  const scrollableElement = useRef<Element | null>(null);
  const scrollInfo = useRef({
    isScrollable: false,
    isAtLeftEdge: false,
    isAtRightEdge: false
  });

  const handleTouchStart = useCallback((evt: TouchEvent) => {
    const touch = evt.touches[0];
    const target = touch.target as Element;

    const info = getScrollInfo(target);
    scrollInfo.current = info;
    scrollableElement.current = info.scrollableElement;

    touchStartX.current = touch.clientX;
    touchStartY.current = touch.clientY;
    isDragging.current = true;
  }, []);

  const handleTouchMove = useCallback((evt: TouchEvent) => {
    if (!isDragging.current) return;

    const touch = evt.touches[0];
    const deltaX = touch.clientX - touchStartX.current;
    const deltaY = touch.clientY - touchStartY.current;
    const angle = Math.abs(Math.atan2(deltaY, deltaX) * (180 / Math.PI));
    const absDeltaX = Math.abs(deltaX);
    const dragRight = deltaX > 0;
    const dragLeft = deltaX < 0;

    if (scrollableElement.current) {
      const currentInfo = getScrollInfo(scrollableElement.current);
      scrollInfo.current = currentInfo;
    }

    const allowDragRight = !scrollInfo.current.isScrollable || scrollInfo.current.isAtLeftEdge;
    const allowDragLeft = !scrollInfo.current.isScrollable || scrollInfo.current.isAtRightEdge;

    if (!dragDirection.current && absDeltaX > dragConfig.dragTreshold / 2) {
      if ((dragRight && allowDragRight) || (dragLeft && allowDragLeft)) {
        dragDirection.current = dragRight ? "right" : "left";
      }
    }

    const dynamicDirection = deltaX > 0 ? "right" : "left";
    const dragThresholdReached = absDeltaX > dragConfig.dragTreshold;
    const angleIsValid = angle <= dragConfig.angleTreshold || angle >= 180 - dragConfig.angleTreshold;
    const directionIsConsistent = dragDirection.current === dynamicDirection;
    const directionIsAllowed = (dragRight && allowDragRight) || (dragLeft && allowDragLeft);
    const isValidDrag = dragThresholdReached && angleIsValid && directionIsConsistent && directionIsAllowed;

    if (isValidDrag) {
      handleValidDrag(dynamicDirection);
    }
  }, []);

  const handleTouchEnd = useCallback(() => {
    isDragging.current = false;
    dragDirection.current = null;
    scrollableElement.current = null;
    scrollInfo.current = {
      isScrollable: false,
      isAtLeftEdge: false,
      isAtRightEdge: false
    };
  }, []);

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd
  };
}
