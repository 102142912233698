import { useLocation } from "wouter-preact";
import { useEffect, useState } from "preact/hooks";
import { findRoute } from "../helpers.ts";
import { Route } from "../types.ts";

export const useCurrentRoute = (routes: Route[]) => {
  const [location] = useLocation();
  const [currentRoute, setCurrentRoute] = useState(() => {
    return findRoute(routes, location);
  });

  useEffect(() => {
    setCurrentRoute(findRoute(routes, location));
  }, [location, setCurrentRoute]);

  return currentRoute;
};
