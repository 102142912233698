import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { ToastMessage } from "@fleetora/core/src/components/Toast.tsx";
import { Carrier, Orderings, Paints } from "@fleetora/core/src/types.ts";

export const apiKeyAtom = atomWithStorage<string | null>("fleetora_api_key", null);
export const isNavigationOpenAtom = atom<boolean>(false);
export const toastAtom = atom<ToastMessage[]>([]);
export const isDialogOpenAtom = atom<boolean>(false);

export const carrierAtom = atom<Carrier | null>();
export const paintsAtom = atom<Paints | null>();
export const orderingsAtom = atom<Orderings | null>();
