// Routing

import { LucideProps } from "lucide-react";
import { ForwardRefExoticComponent, JSX, RefAttributes } from "preact/compat";

export interface Route {
  path: string;
  className: string;
  labels: {
    navigation: string;
    header?: string;
    dataProvider?: string;
    useCustomHeader?: boolean;
  };
  component: () => JSX.Element;
  icon?: ForwardRefExoticComponent<Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>>;
  hidden?: boolean;
  default?: boolean;
  hasSidebar?: boolean;
}

// API Generics

export interface ApiError {
  type: string;
  message: string;
}

export interface ApiResponse<T> {
  data: T | null;
  error: ApiError | null;
  isLoading: boolean;
  abort: () => void;
}

export type ApiOptions = {
  headers?: Record<string, string>;
  params?: Record<string, string>;
  signal?: AbortSignal;
  abortOnUnmount?: boolean;
};

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type JsonObject = Record<string, unknown>;

export enum TractionType {
  Tram = "Tram",
  Trolleybus = "Trolleybus",
  Bus = "Bus"
}

export enum DeploymentFlag {
  HighDelay = "HighDelay",
  RareOccurence = "RareOccurence",
  LowCount = "LowCount"
}

export enum DayOfWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday"
}

// CDN

export type Carrier = Record<string, Model[]>;

export interface Model {
  name: string;
  registrationNumbers: number[];
}

export type Paints = Record<string, Paint>;

export interface Paint {
  id: string;
  name: string;
}

export type Orderings = Record<number, string>;

export interface ProvozSnapshot {
  age: number;
  lastUpdatedAt: Date;
  dayOfWeek: DayOfWeek;
  vehicles: ProvozVehicle[];
}

// API Objects

export interface ProvozVehicle {
  id: string;
  lineMeta: LineMeta;
  connectionId: number;
  delayMinutes: number;
  lastStopIndex: number;
  lastStopName: string;
  nextStopIndex: number;
  nextStopName: string;
  startName: string;
  destinationName: string;
  isAirConditioned: boolean;
  quickMessageIds: number[];

  //Additional properties from SearchVehicles
  deploymentFlags: DeploymentFlag[];
  manufactureYear: number;
  lastPhotoId: number;
  lastPhotoAge: number;

  //Calculated properties
  registrationNumber: number;
  tractionType: TractionType;
}

export interface LineMeta {
  name: string;
  tractionType: string;
}

export interface Shift {
  isHalved: boolean;
  endsAt: Date;
}

export interface Course {
  originalLine: string;
  order: number;
}

export interface Trip {
  id: number;
  line: string;
  departures: Departure[];
}

export interface Departure {
  stopName: string;
  departuresAt: Date;
  isOnRequest: boolean;
}

export interface PhotoMeta {
  author: string;
  age: number;
}

export type Stops = Record<string, number>;

export interface StopDeparture {
  connectionId: number;
  lineMeta: LineMeta;
  departuresAt: Date;
  destinationName: string;
  delayMinutes: number;
  isAirConditioned: boolean;
  vehicleId: string;
  course: Course;

  //Calculated properties

  registrationNumber: number;
  tractionType: TractionType;
}

export enum ChronicleTag {
  Line = "Line",
  Timetable = "Timetable",
  Fleet = "Fleet",
  Change = "Change",
  HistoricalEvent = "HistoricalEvent",
  Tariff = "Tariff",
  Infrastructure = "Infrastructure",
  Other = "Other"
}

export interface ChronicleGetBody {
  skip?: number;
  after?: string;
  before?: string;
  tags?: ChronicleTag[];
}

export interface ChronicleGetResult {
  entries: ChronicleEntry[];
  hasMore: boolean;
  totalCount: number;
}

export interface ChronicleEntry {
  timestamp: Date;
  content: string;
  tags: ChronicleTag[];
}

export enum ContactType {
  Feedback = "Feedback",
  FeatureRequest = "FeatureRequest",
  BugReport = "BugReport",
  TramSetChange = "TramSetChange",
  PaintChange = "PaintChange",
  InaccurateData = "InaccurateData",
  Other = "Other"
}

export interface ContactBody {
  email: string;
  contactType: ContactType;
  content: string;
}

export interface SearchHistoryTableBody {
  month: number;
  year: number;
  modelName: string;
}

export interface DayOfWeekRewrite {
  date: Date;
  dayOfWeek: DayOfWeek;
  note: string | null;
}

export interface SearchHistoryDeployment {
  date: Date;
  line: string;
  order: number;
}

export interface SearchHistoryTableResult {
  dayOfWeekRewrites: DayOfWeekRewrite[];
  data: Record<number, SearchHistoryDeployment[]>;
}

export enum NoticeColor {
  Red = "Red",
  Yellow = "Yellow",
  Green = "Green"
}

export interface Notice {
  title: string;
  content: string;
  color: NoticeColor;
}
