import { Menu } from "lucide-react";
import { Dispatch, StateUpdater } from "preact/hooks";

interface HamburgerProps {
  setIsOpen: Dispatch<StateUpdater<boolean>>;
}

export default function Hamburger({ setIsOpen }: HamburgerProps) {
  return (
    <div class="hamburger" onClick={() => setIsOpen((prev) => !prev)}>
      <div class="icon">
        <Menu size={40} />
      </div>
    </div>
  );
}
