import { ContactType, TractionType } from "./types.ts";

export const developmentOutdatedTreshold = 600;
export const mobileBreakpoint = 720;
export const toastTimeout = 3000;
export const allowedContentTypes = ["image/jpeg", "image/png", "image/heif", "image/heic"];
export const maxFileSize = 8 * 1000 * 1000;

export const tramColor = "#f0be32";
export const trolleybusColor = "#1e9641";
export const busColor = "#cd2837";

export const dragConfig = {
  dragTreshold: 50,
  edgeThreshold: 100,
  angleTreshold: 50
};

export const tractionToHumanMap = new Map<TractionType, string>([
  [TractionType.Tram, "Tramvaje"],
  [TractionType.Trolleybus, "Trolejbusy"],
  [TractionType.Bus, "Autobusy"]
]);

export const tractionToNumberMap = new Map<TractionType, number>([
  [TractionType.Tram, 1],
  [TractionType.Trolleybus, 2],
  [TractionType.Bus, 3]
]);

export const numberToTractionMap = new Map<number, TractionType>([
  [1, TractionType.Tram],
  [2, TractionType.Trolleybus],
  [3, TractionType.Bus]
]);

export const tractionToSeznamNumberMap = new Map<TractionType, number>([
  [TractionType.Tram, 1],
  [TractionType.Trolleybus, 3],
  [TractionType.Bus, 5]
]);

export const contactTypeToHumanMap = new Map<ContactType, string>([
  [ContactType.Feedback, "Obecná zpětná vazba"],
  [ContactType.FeatureRequest, "Návrh nové funkce nebo vylepšení"],
  [ContactType.BugReport, "Chyba v aplikaci"],
  [ContactType.TramSetChange, "Změna řazení tramvajové soupravy"],
  [ContactType.PaintChange, "Změna nátěru vozu"],
  [ContactType.InaccurateData, "Nesprávné nebo chybějící údaje"],
  [ContactType.Other, "Jiné"]
]);

export const humanToContactTypeMap = new Map<string, ContactType>([
  ["zmena-razeni", ContactType.TramSetChange],
  ["zmena-nateru", ContactType.PaintChange]
]);

export const timeProperties = ["departuresAt", "startsAt", "endsAt", "date", "timestamp"];
export const timeSpanRegex = /^(\d{2}):(\d{2}):(\d{2})$/;
export const iso8601Regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;
export const dateOnlyRegex = /\d{4}-\d{2}-\d{2}$/;
export const emailRegex = /[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;
export const secondsInYear = 365 * 24 * 60 * 60;
export const secondsInMonth = 30 * 24 * 60 * 60;
export const secondsInDay = 24 * 60 * 60;
export const secondsInHour = 60 * 60;
export const secondsInMinute = 60;
export const contentMaxLength = 1000;
export const tramSetModelNames = ["ČKD Tatra T3R.P", "Pragoimex T3R.PLF", "Pragoimex Vario LFR.S", "Pragoimex VarioLF Plus"];
