import { RotateCcw, Flag } from "lucide-react";
import { Component, ErrorInfo } from "preact";
import TagButton from "./TagButton.tsx";
import { useLocation } from "wouter-preact";

import "../styles/error-boundary.scss";

interface ErrorBoundaryProps {
  children: preact.ComponentChildren;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    const [, navigate] = useLocation();

    console.log("Error boundary render", this.state);
    console.log("Rendering error boundary", this.state.hasError && this.state.error != undefined);

    if (this.state.hasError && this.state.error != undefined) {
      const isNetworkError =
        this.state.error.name &&
        this.state.error.message &&
        (this.state.error.name === "NetworkError" || this.state.error.message.includes("error loading dynamically imported module") || this.state.error.message.includes("Unable to preload CSS"));

      return (
        <div class="error-boundary">
          <h2 class="title">{isNetworkError ? `Nedostupné připojení k internetu` : `Neočekáváná chyba při běhu stránky`}</h2>
          <p class="message">{isNetworkError ? `Zkontroluj své připojení k internetu a zkus to znovu.` : `Prosím, zkus to znovu později.`}</p>
          {!isNetworkError && "message" in this.state.error && (
            <p class="error-details">
              Detaily chyby:{" "}
              <code>
                {this.state.error.name ? `${this.state.error.name}: ` : ""}
                {this.state.error.message}
              </code>
            </p>
          )}

          <div class="buttons">
            <TagButton variant="secondary" icon={{ component: <RotateCcw />, placement: "left" }} onClick={() => location.reload()}>
              Obnovit stránku
            </TagButton>

            {!isNetworkError && (
              <TagButton variant="secondary" icon={{ component: <Flag />, placement: "left" }} onClick={() => navigate("/kontakt")}>
                Nahlásit chybu
              </TagButton>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
