import { useAtom } from "jotai";
import Hamburger from "@fleetora/core/src/components/Hamburger.tsx";
import { useCurrentRoute } from "@fleetora/core/src/hooks/useCurrentRoute.ts";
import useDeviceType from "@fleetora/core/src/hooks/useDeviceType.ts";
import { isNavigationOpenAtom } from "src/store.ts";
import { routes } from "src/routes.ts";

import "src/styles/header.scss";

export default function Header() {
  const [, setIsNavigationOpen] = useAtom(isNavigationOpenAtom);

  const isMobile = useDeviceType();
  const currentRoute = useCurrentRoute(routes);

  //Only render on mobile
  if (!isMobile) return null;

  return (
    <div class="header">
      <Hamburger setIsOpen={setIsNavigationOpen} />

      {currentRoute && <h1 class="title">{currentRoute.labels.header || currentRoute.labels.navigation}</h1>}
    </div>
  );
}
